import {
  Alert,
  AlertIcon,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import type { LoginInput } from '@twlvxtwlv/types'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { HiEyeSlash, HiOutlineEye } from 'react-icons/hi2'
import { z } from 'zod'

const schema = z.object({
  email: z.string().email().max(128),
  password: z.string().min(1),
})

export function LoginForm({
  error,
  onSubmit,
  onGotoSignUp,
  onPasswordReset,
}: {
  error?: string
  onSubmit: (loginInput: LoginInput) => void
  onPasswordReset: () => void
  onGotoSignUp: () => void
}) {
  const { t } = useTranslation('user-management')

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<LoginInput>({
    mode: 'onBlur',
    resolver: zodResolver(schema),
  })

  const [showPassword, setShowPassword] = useState(false)
  const handleClick = () => setShowPassword(!showPassword)

  return (
    <Stack as="form" w="full" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={0} gap={8} w="full">
        <Stack gap={2} w="full">
          <FormControl isInvalid={!!errors.email} isRequired>
            <FormLabel fontWeight="bold" htmlFor="email">
              {t('email')}
            </FormLabel>
            <Input
              id="email"
              type="email"
              placeholder={t('email-placeholder') ?? undefined}
              autoComplete="email"
              {...register('email')}
              variant="outline"
            />
            {errors.email && (
              <FormErrorMessage>{t('missing-field', { fieldName: t('email') })}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.password} isRequired>
            <FormLabel fontWeight="bold" htmlFor="password">
              {t('password')}
            </FormLabel>
            <InputGroup>
              <Input
                id="password"
                type={showPassword ? 'text' : 'password'}
                placeholder={t('password') ?? undefined}
                autoComplete="password"
                {...register('password')}
                variant="outline"
              />
              <InputRightElement>
                <IconButton
                  bg="transparent"
                  color="black"
                  border="none"
                  size="xs"
                  aria-label={t(showPassword ? 'password-hidden' : 'password-show')}
                  as={showPassword ? HiEyeSlash : HiOutlineEye}
                  onClick={handleClick}
                  _hover={{
                    cursor: 'pointer',
                  }}
                />
              </InputRightElement>
            </InputGroup>
            {errors.password && (
              <FormErrorMessage>{t('password-login-helper-text')}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>

        {error && (
          <Center>
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          </Center>
        )}

        <Center pt={4}>
          <Button isLoading={isSubmitting} type="submit">
            {t('login')}
          </Button>
        </Center>

        <Stack pt={4} gap={8} textAlign="center">
          <Text mr={1}>
            {t('not-registered')}{' '}
            <Link variant="txtBlue" onClick={onGotoSignUp}>
              {t('sign-up')}
            </Link>
          </Text>
          <Link variant="txtBlack" onClick={onPasswordReset}>
            {t('forgotten-your-password')}
          </Link>
        </Stack>
      </Stack>
    </Stack>
  )
}
