import { Divider, Flex, Spacer, Stack } from '@chakra-ui/react'
import Image from 'next/image'
import type { ReactNode } from 'react'
import { ChangeLanguage } from '../change-language'
import { CloseButtonBar } from '../close-button-bar'

interface TaskContainerSidebarProps {
  children: ReactNode
  onClose: () => void
}

export function TaskContainerSidebar({ children, onClose }: TaskContainerSidebarProps) {
  return (
    <Stack
      align={['center', 'center', 'center', 'stretch']}
      h="full"
      w="full"
      overflow="none"
      borderRadius={[20, 20, 20, 0]}
      py={[5, 5, 0]}
      bg="white"
    >
      <Stack w="full" h="full" justify="space-between" overflow="auto" px={[0, 0, 16, 20]}>
        <CloseButtonBar onClose={onClose} />
        <Stack flexGrow={1} w="full" justify="center" align="center" px={5}>
          <Stack w="full" maxW="full" mt={1} gap={4} align="center">
            {children}
          </Stack>
          <Spacer />
          <Stack w="full" align="center" gap={10} py={8} pt={24} overflowX="hidden">
            <Flex gap={8} align="center">
              <Divider width={80} />
              <ChangeLanguage variant="light" withLabel={false} />
              <Divider width={80} />
            </Flex>
            <Flex justify="center">
              <Image
                src="/images/twelve-text-logo.svg"
                priority={true}
                width="150"
                height="21"
                alt="txt-text-logo"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </Flex>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
