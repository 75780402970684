import { Heading } from '@chakra-ui/react'
import type { LoginInput } from '@twlvxtwlv/types'
import { LoginForm } from '@twlvxtwlv/ui/src/components/shared/forms/login-form'
import { TaskContainer } from '@twlvxtwlv/ui/src/components/shared/task-container'
import type { GetServerSideProps, GetServerSidePropsContext } from 'next'
import { signIn } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useState } from 'react'

export default function LoginPage() {
  const { t } = useTranslation(['user-management', 'common'])
  const [loginError, setLoginError] = useState<string | undefined>(undefined)
  const router = useRouter()
  const { origin } = router.query

  async function handleSubmit({ email, password }: LoginInput) {
    setLoginError(undefined)
    const route = origin ? `${origin}` : '/'

    const response = await signIn('token-forge', {
      email,
      password,
      redirect: false,
    })
    if (!response || response.status === 401) {
      setLoginError(t('login-unauthorized') ?? undefined)
    } else if (response.status === 200) {
      await router.push(route)
    } else {
      setLoginError(`${response.status} ${response.error}`)
    }
  }

  function handleGotoSignUp() {
    router.push('/sign-up')
  }

  function handlePasswordReset() {
    router.push('/forgotten-password')
  }

  return (
    <>
      <Head>
        <title>{t('common:meta-title-login')}</title>
        <meta name="description" content={t('common:meta-description-login') ?? undefined} />
      </Head>
      <TaskContainer>
        <Heading w="full" as="h3">
          {t('login-title')}
        </Heading>

        <LoginForm
          onSubmit={handleSubmit}
          onGotoSignUp={handleGotoSignUp}
          onPasswordReset={handlePasswordReset}
          error={loginError}
        />
      </TaskContainer>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async ({
  locale = 'de',
}: GetServerSidePropsContext) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'user-management'])),
    },
  }
}
