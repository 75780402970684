import { Flex, Show, Stack } from '@chakra-ui/react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import type { ReactNode } from 'react'
import { TaskContainerSidebar } from './task-container-sidebar'

interface TaskContainerProps {
  bgImage?: string | null | undefined
  bgRepeat?: boolean
  children: ReactNode
  onClose?: () => void
}

export function TaskContainer({
  bgImage,
  bgRepeat = false,
  children,
  onClose,
}: TaskContainerProps) {
  const router = useRouter()

  const backgroundImage = bgImage ?? '/images/twelvextwelve.com-background-image.webp'

  function handleOnClose() {
    onClose ? onClose() : router.back()
  }

  return (
    <>
      <Show below="lg">
        <Stack
          align={['center', 'center', 'flex-end']}
          w="full"
          bgImage={backgroundImage}
          bgRepeat={bgRepeat ? 'repeat' : 'no-repeat'}
          bgSize={bgRepeat ? 'auto' : 'cover'}
          bgPosition="center"
          p={[2, 4, 8, 0]}
        >
          <TaskContainerSidebar onClose={handleOnClose}>{children}</TaskContainerSidebar>
        </Stack>
      </Show>

      <Show above="lg">
        <Flex w="full" h="100vh">
          <Stack h="full" w="full" position="relative">
            <Image
              alt="Claim page background image"
              src={backgroundImage}
              fill
              style={{ objectFit: 'cover' }}
            />
          </Stack>
          <Stack
            w="full"
            minW={{ base: 'full', md: '500px' }}
            maxW={{ lg: '650px', xl: '750px' }}
            h="full"
          >
            <TaskContainerSidebar onClose={handleOnClose}>{children}</TaskContainerSidebar>
          </Stack>
        </Flex>
      </Show>
    </>
  )
}
